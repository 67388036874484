var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://dev-api.virtualcp.mksdigitech.com';
} else {
    // service.API_URL = 'https://dev-api.virtualcp.mksdigitech.com';
    service.API_URL = 'http://192.168.1.111:5003';
}

export default service;
